<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <a
                    class="btn btn-primary mr-1"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="true"
                    aria-controls="collapseExample"
                    >Filter</a
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    label="Search"
                  />
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="collapse" id="collapseExample">
                <div class="card card-body">
                  <p class="font-weight-bold">Filter</p>
                  <div class="row">
                    <div class="col-4">
                      <model-list-select
                        :list="venues"
                        v-model="filter.venue_id"
                        option-value="value"
                        option-text="text"
                        placeholder="Select Venue"
                      >
                      </model-list-select>
                    </div>
                    <div class="col-4">
                      <model-list-select
                        :list="products"
                        v-model="filter.product_id"
                        option-value="id"
                        option-text="name"
                        placeholder="Select Product"
                      >
                      </model-list-select>
                    </div>
                    <div class="col-4">
                      <model-list-select
                        :list="units"
                        v-model="filter.unit_id"
                        option-value="id"
                        option-text="name"
                        placeholder="Select Unit"
                      >
                      </model-list-select>
                    </div>
                    <div class="col-12">
                      <button
                        class="btn btn-primary w-100 mt-1"
                      >
                        Filter Product
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                v-if="loading"
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                v-if="!loading" 
                :items="pricePerVenues"
                item-key="id"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductShow',
                          params: { id: row.item.id },
                        }"
                        >{{ row.item.name }}</router-link
                      >
                    </td>
                    <td>{{ row.item.category_name }}</td>
                    <td>{{ row.item.type_name || "NO TYPE" }}</td>
                    <td>
                      <img
                        :src="row.item.image"
                        :alt="row.item.name"
                        height="100px"
                      />
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductEdit',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                      <button
                        @click="deleteProduct(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <div class="d-inline-block overflow-auto"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { VenueRepository } from "../../repositories/RepositoryFactory";
import { apiGet } from "../../services/api";
export default {
  data() {
    return {
      name: "Product Price",
      filter: {
          venue_id: null,
          product_id: null,
          unit_id: null
      },
      pricePerVenues: [],
      loading: true,
      venues: [],
      products: [],
      units: []
    };
  },
  mounted() {
    this.getVenues();
    this.getProducts();
    this.getUnits();
    this.getData();
  },
  methods: {
    getData() {
        apiGet('product_price', this.filter).then(res => {
            this.pricePerVenues = res.data.data.price_per_venue
        }).finally(() => {
            this.loading = false
        })
    },
    getVenues() {
      VenueRepository.getForSelect().then((result) => {
        this.venues = result.data.data;
      });
    },
    getProducts() {
        apiGet('product/all', {
            venue_id: this.venue_id
        }).then(res => {
            this.products = res.data.data
        })
    },
    getUnits() {
        apiGet('unit').then(res => {
            this.units = res.data.data
        })
    }
  },
  computed: {
      headers() {
      return [
        {
          text: "Venue",
          value: "name_venue",
        },
        {
          text: "Category",
          value: "category_id",
        },
        {
          text: "Type",
          value: "type_id",
        },
        {
          text: "Image",
          value: "image",
        },
        {
          text: "Action",
          value: "action",
        },
      ];
    },
  },
  watch: {
      filter: {
          handler(val) {
              this.getData()
          },
          deep: true
      }
  }
};
</script>